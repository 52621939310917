define('client/mixins/check-term-count-exists', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  function _toConsumableArray(arr) {
    if (Array.isArray(arr)) {
      for (var i = 0, arr2 = Array(arr.length); i < arr.length; i++) {
        arr2[i] = arr[i];
      }

      return arr2;
    } else {
      return Array.from(arr);
    }
  }

  exports.default = Ember.Mixin.create({

    modal: Ember.inject.service(),

    CheckTermCountExists: function CheckTermCountExists(searchTerm, termCounts) {
      var finalTermCounts = termCounts.map(function (e) {
        return e.toLowerCase();
      });
      var matches = [].concat(_toConsumableArray(new Set((searchTerm.match(/"([^"]+)"(?=[\s]*[>=<])/g) || []).filter(Boolean).map(function (match) {
        return match.replace(/"/g, '').toLowerCase();
      }).filter(Boolean))));
      var missingTerms = matches.filter(function (term) {
        return !finalTermCounts.includes(term);
      });
      if (missingTerms.length) {
        var errorMessage = 'Alert: Your search terms ' + missingTerms.join(', ') + ' not found. Please add here, Settings > Term Counts';
        this.get('modal').open('common/ui-confirm', {
          prompt: errorMessage,
          ok: 'OK',
          okType: "danger",
          showCancel: false
        });
        return false;
      }
      return true;
    }
  });
});