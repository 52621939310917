define('client/controllers/settings/term-counts/index', ['exports', 'client/mixins/data-table-sort'], function (exports, _dataTableSort) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Controller.extend(_dataTableSort.default, {
    modal: Ember.inject.service(),

    resources: Ember.computed.alias("termCounts"),

    _sorts: ["Created_At", "Term_Display", "Term_Value"],
    edit: false,
    editTermCountData: null,

    termCounts: Ember.computed(function () {
      var owner = Ember.getOwner(this);
      var findPagingController = owner.lookup('controller:find-paging');

      findPagingController.setProperties({
        model: [],
        type: "term_count",
        store: this.get("store")
      });
      Ember.run.later(this, "paramsDidChange");
      return findPagingController;
    }),

    newTermCount: Ember.computed('profile', function () {
      return this.createRecord();
    }),
    caseTypes: Ember.computed(function () {
      return ['insensitive', 'sensitive'];
    }),

    paramsDidChange: Ember.observer("sortCreated_At", "sortTerm_Display", "sortTerm_Value", function () {
      Ember.run.scheduleOnce("afterRender", this, "_paramsDidChange");
    }),

    createRecord: function createRecord() {
      return this.store.createRecord('term_count', {
        profile: this.get('profile'),
        user_id: this.get('user.id'),
        case_type: this.caseTypes[0]
      });
    },

    actions: {
      editTermCount: function editTermCount(termCount) {
        this.set("editTermCountData", null);
        this.set("editTermCountData", JSON.stringify(termCount.get('content')));
        this.set("edit", true);
        this.set("newTermCount", termCount);
      },
      cancelEdit: function cancelEdit() {
        this.set("newTermCount", this.createRecord());
        this.set("edit", false);
      },
      updateTermCount: function updateTermCount(termCount) {
        var _this = this;

        this.set('termCounts.isLoadingPage', true);

        termCount.get('content').save().then(function () {
          _this.notifyPropertyChange("termCounts");
          _this.notifyPropertyChange("termCount");
        }).catch(function (failure) {
          var error = failure.errors && failure.errors[0] || failure;
          _this.get('modal').open('common/ui-confirm', {
            prompt: error,
            ok: 'OK',
            okType: "danger",
            showCancel: false
          }).then(function () {
            termCount.set('term_display', JSON.parse(_this.get('editTermCountData')).term_display);
            termCount.set('term_value', JSON.parse(_this.get('editTermCountData')).term_value);
          });
        });

        this.set('termCounts.isLoadingPage', false);
        this.send('cancelEdit');
      },
      addTermCount: function addTermCount(newTermCount) {
        var _this2 = this;

        newTermCount.set('case_type_cd', this.caseTypes.indexOf(newTermCount.case_type));
        this.set('termCounts.isLoadingPage', true);

        newTermCount.save().then(function () {
          _this2.notifyPropertyChange("termCounts");
          _this2.notifyPropertyChange("newTermCount");
        }).catch(function (failure) {
          var error = failure.errors[0] ? failure.errors[0].detail : failure;
          _this2.get('modal').open('common/ui-confirm', {
            prompt: error,
            ok: 'OK',
            okType: "danger",
            showCancel: false
          });
        });

        this.set('termCounts.isLoadingPage', false);
        this.send('cancelEdit');
      },
      removeTermCount: function removeTermCount(termCount) {
        var _this3 = this;

        this.get('modal').open('common/ui-confirm', {
          prompt: 'Are you sure you want to remove?',
          ok: "Delete",
          okType: "danger"
        }).then(function () {
          _this3.set('termCounts.isLoadingPage', true);
          termCount.get('content').destroyRecord().then(function () {
            _this3.set('termCounts.isLoadingPage', false);
            _this3.get("termCounts.model").removeObject(termCount);
            _this3.notifyPropertyChange("termCounts");
          });
        });
      }
    }
  });
});